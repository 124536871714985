import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"


const divContainerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.25,
        },
    }
}

const divSquareVariants = {
    hidden: { opacity: 0 }, show: { opacity: 1 }
}

const ChirurgieCard = () => {

    const sloganVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
            <div className="">
                <div className="">
                    <div className="">
                        <h2 className="text-xl font-semibold">CONSULTATION EXTERNE</h2>
                        <h5 className="text-xl font-semibold">Contact: 038 06 936 15</h5>
                        <p><strong>Consultation spécialisée :</strong></p>
                        <ul className="">
                            <li>-Chirurgie Orthopédique et traumatologique</li>
                            <li>-Chirurgie pédiatrique</li>
                            <li>-Chirurgie générale</li>
                            <li>-Unité d'endoscopie</li>
                            <li>-Chirurgie laparoscopique</li>
                        </ul>
                        <p><strong>Bilan d’investigation</strong></p>
                        <ul className="">
                            <li>-Examen biologique</li>
                            <li>-Imagerie : Radiographie/Echographie/Scanner</li>
                        </ul>
                        <p><strong>Préparation à l’intervention chirurgicale</strong></p>
                        <ul className="">
                            <li>Bilan préopératoire (Biologie ; Imagerie)</li>
                            <li>Consultation pré anesthésique au Service réanimation</li>
                            <li>Programmation opératoire</li>
                            <li>Contrôle sur rendez-vous</li>
                        </ul>
                        <p><strong>Lieu :</strong></p>
                        <p>1er bâtiment, 1Er étage à gauche</p>
                        <p>Porte 1212 : Chirurgie Traumatologique et Orthopédique</p>
                        <p>Porte 1213 : Chirurgie Générale et Pédiatrique</p>
                        <p>Lundi au Vendredi 08h 30 à 12h / 14h à 16h</p>
                        <p>Sans rendez-vous ou sur rendez-vous</p>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-xl font-semibold">HOSPITALISATION</h2>
                        <p>Prise en charge des cas chirurgicaux, adulte et enfant, venant du service des urgences, de la consultation externe et des autres services :</p>
                        <ul className="">
                            <li>Chirurgie orthopédique, malformation des membres</li>
                            <li>Chirurgie générale : digestive, urologie, gynécologie, sein, thyroïde</li>
                            <li>Chirurgie pédiatrique : viscérale et traumatologie</li>
                            <li>Admission des patients programmés la veille du jour d’intervention</li>
                        </ul>
                        <p><strong>Lieu :</strong></p>
                        <p>3ème bâtiment, 1Er étage à droite</p>
                        <p>1ère catégorie : 2 salles de 2 lits </p>
                        <p>3ème catégorie : 4 salles de 3 lits </p>
                        <p>4ème catégorie : 1 salle de 6 lits </p>
                        <p>02 salles de bloc opératoire</p>
                        <p>Tous les jours 24h sur 24h</p>
                        <p>Porte 1213 : Chirurgie Générale et Pédiatrique</p>
                        <p>Lundi au Vendredi 08h 30 à 12h / 14h à 16h</p>
                        <p>Sans rendez-vous ou sur rendez-vous</p>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-xl font-semibold">DOSSIER A FOURNIR :</h2>
                        <p><strong>CONSULTATION EXTERNES</strong></p>
                        <ul className="">
                            <li>-01 carnet</li>
                            <li>-Frais de consultation</li>
                            <li>-Bulletin de consultation (Prise en charge, remboursement)</li>
                            <li>-Lettre de liaison ou lettre de référence</li>
                        </ul>
                        <p><strong>CONTROLE SUR RENDEZ-VOUS : à présenter</strong></p>
                        <ul className="">
                            <li>-Carnet</li>
                            <li>-Objets du pansement</li>
                            <li>-Résultat d’analyse et / ou d’imagerie</li>
                            <li>-Drap</li>
                        </ul>
                    </div>

                    <div className="">
                        <h2 className="text-xl font-semibold">HOSPITALISATION</h2>
                        <ul className="">
                            <li>Billet d’hôpital à récupérer au service des urgences</li>
                            <li>Dossier médical</li>
                            <li>Faits personnels : drap, couverture, pyjama, serviette</li>
                        </ul>
                    </div>

                    <div className="">
                        <h2 className="text-xl font-semibold">INFORMATIONS UTILES</h2>
                        <ul className="">
                            <li>Acte opératoire à payer à la cession dans 24H de l’opération</li>
                            <li>Hébergement à consulter au service</li>
                            <li>Heure de visite des malades à respecter</li>
                            <ul className="">
                                <li>Matin :06h à 07h</li>
                                <li>Midi :12h à 14h</li>
                                <li>Soir :17h à 19h</li>
                            </ul>
                            <li>Hygiène et règlement intérieur à respecter</li>
                            <li>Les médicaments et les repas sont à la charge du patient</li>
                        </ul>
                        <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                        <ul>
                            <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>

                        </ul>
                    </div>
                </div>
            </div>
    );
}
export default ChirurgieCard;