import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accueil from "./Pages/Accueil1.jsx";
import Actualite from "./Pages/Actualites.jsx";
import Navbar1 from "./Pages/NavBar1.jsx";
import './App.css';
import About from "./Pages/About.jsx";
import Login from "./Pages/Login.jsx";
import Services from "./Pages/Services2.jsx";
import Contact from "./Pages/Contact.jsx";
 
function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar1/>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/Actualites" element={<Actualite />} />
          <Route path="/Services" element={<Services/>} />
          <Route path="/About" element={<About />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
