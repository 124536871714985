import React from 'react';
import { Card, CardBody } from 'flowbite-react';
import { motion } from "framer-motion"
const MprCard = () => {
    return (
        <>
            <div className="medecine-physique-readaptation">
            <div>
                <h3 className='justify-center font-bold text-xl'>Présentation du service</h3>
                <p>
                    <strong>CHAMPS D'ACTION</strong><br/>
                    La Médecine Physique et de Réadaptation (MPR) est la spécialité médicale qui se concentre sur la récupération des capacités fonctionnelles et de la qualité de vie des patients atteints de handicaps, qu'ils soient congénitaux ou acquis. Elle est responsable de la prévention, du diagnostic, du traitement et de la réhabilitation des personnes de tout âge ayant subi des conséquences médicales responsable de handicap et de comorbidités.
                </p>
                <p>
                    Le service MPR propose à ses patients une offre personnalisée de soins humains de qualité.
                </p>
            </div>
        </div>

        <div className="contact">
            <div>
                <h2 className='justify-center font-bold text-xl'>Contact</h2>
                <p>-Téléphone: 038 90 530 27 </p>
            </div>
        </div>

        <div className="open-hour">
            <div>
                
                <h2 className='justify-center font-bold text-xl'>Horaire D'ouveture</h2>
                <p>-Du Lundi au Vendredi de 08H à 16H sauf jours fériés</p>
            </div>
        </div>

        <div className="pathologies-prises-en-charge">
            <div>
                <h3 className='justify-center font-bold text-xl'>Pathologies prises en charge</h3>
                <ul>
                    <li>
                        <strong>Pathologies orthopédiques et post-traumatiques :</strong><br/>
                        -Déformations et désaxations rachidiennes (scoliose, cyphose, camptocormies, …)<br/>
                        -Déformations et/ou séquelles de traumatismes des membres supérieurs et inférieurs,<br/> 
                        -Pathologies post-fracturaires, amputations de membres, …
                    </li>
                    <li>
                        <strong>Pathologies rhumatologiques :</strong><br/>
                        -Syndromes douloureux du rachis et des racines nerveuses (cervicalgies communes, dorsalgies communes, lombalgies communes, névralgies cervico-brachiale, lombosciatalgies, …)<br/>
                        -Pathologies articulaires (arthroses, autres arthropathies)<br/>
                        -Pathologies juxta- et extra-articulaires (tendinopathies, fibromyalgies, …)
                    </li>
                    <li>
                        <strong>Pathologies neurologiques :</strong><br/>
                        -Post-AVC, séquelles de traumatisme crânien, Maladie de Parkinson, Sclérose en plaques, Séquelles de traumatisme vertébro-médullaire, Séquelles de lésions de la moëlle épinière…
                    </li>
                    <li>
                        <strong>Pathologies pédiatriques :</strong><br/>
                        -Paralysie cérébrale, Spina Bifida, Arthrogrypose multiple congénitale, Bronchiolite, Asthme du nourrisson et de l’enfant…
                    </li>
                    <li>
                        <strong>Pathologies respiratoires :</strong><br/>
                        -Pleurésie, Bronchopneumopathies, Asthme, BPCO, …
                    </li>
                    <li>
                        <strong>Gynéco-obstétrique :</strong><br/>
                        -Préparation à l’accouchement
                    </li>
                    <li>
                        <strong>Gériatrie :</strong><br/>
                        -Prévention de chute chez le sujet âgé
                    </li>
                    <li>
                        <strong>Toutes pathologies responsables de handicap de façon temporaire ou permanent</strong>
                    </li>
                </ul>
            </div>
        </div>

        <div className="informations-patients">
            <div>
                <h2 className='justify-center font-bold text-xl'>Informations aux patients</h2>
                <ul>
                    <li>-Se munir de tous les résultats des bilans sanguins et d'imageries (clichés radiographiques, scanner, IRM, …)</li>
                    <li>-Se munir d'un nouveau carnet</li>
                    <li>-Se munir d'un drap lors des séances</li>
                    <li>-Port de tenue de sport (T-shirt, jogging, basket) lors des séances</li>
                    <li>-Toute absence doit être justifiée (Absence de justification ⇔ consommation de la/des séance(s))</li>
                </ul>

                <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                <ul>
                    <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                    
                </ul>
            </div>
        </div>

        </>
    );
}

export default MprCard;
