import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"

const PharmacyCard = () => {
  return (
    <>
      <div>
        <p className="">
          Non seulement la pharmacie assure la dispensation des médicaments et des consommables médicaux, mais il assure également :
        </p>
      </div>
      <div>
        <ul className="">
          <li>La disponibilité et accessibilité des médicaments à l’hôpital</li>
          <p className="">Tous les médicaments nécessaires aux soins des patients hospitalisés devraient figurer dans la liste des médicaments disponibles, leur prix doit être abordable aux usagers.</p>
          <li>La qualité des produits</li>
          <p className="">Dès la commande jusqu’à la dispensation, une démarche rigoureuse suivant des normes nationales est appliquée pour garantir la qualité des produits de soin.</p>
          <p className="">Pour la commande, la sélection des produits à commander repose sur l’avis technique du comité de médicament ; composé de tous les chefs de service, leur raisonnement se base sur des études cliniques et des expériences vécues.</p>
          <p className="">Concernant l’entreposage, des normes s’appliquent et contrôles par jour afin de garantir l’intégrité des produits, surtout pour les médicaments nécessitant la chaine de froid et les stupéfiants.</p>
          <p className="">À la dispensation, des conseils concernant le suivi de l’ordonnance et la conservation du médicament sont donnés. Il est à la charge de la pharmacie d’éclaircir les problèmes d’incompréhension de l’ordonnance ou d’informer le prescripteur si des problèmes se posent.</p>
          <p className="">Des informations en retour venant des services cliniques sont transmises s’il existe des problèmes concernant l’utilisation des produits délivrés, ces problèmes sont rapportés au conseil du comité de médicament.</p>
        </ul>
      </div>
      <div>
        <div>
          <h6 className="text-xl font-semibold text-gray-800 dark:text-gray-300">
            Fonctionnement du service
          </h6>
          <ul className="">
            <li>Ouvert 24h/24, le personnel fait le tour de garde ; il devrait y avoir au minimum deux personnes dans la pharmacie pendant le jour, y compris le chef de service, et une garde la nuit.</li>
            <li>La pharmacie dispense des médicaments et des consommables médicaux provenant du fournisseur, obtenus soit par achat direct, soit par recouvrement de coût, ou par subvention de l’État. Une grande partie des subventions est utilisée pour le fonctionnement de l’hôpital et pour les soins des personnes démunies.</li>
            <p className="">De ce fait, il est impératif que les patients ou leur famille apportent une contribution à l’achat des médicaments.</p>
            <p className="">Pour les cas d’urgences, les médicaments sont immédiatement délivrés et si la famille ne dispose pas encore de la somme nécessaire pour l’achat, un engagement est fixé entre le service de la pharmacie et la personne concernée.</p>
            <li>Les médicaments délivrés doivent être en bon état, ne dépassant pas la date de péremption indiquée sur la couverture ; ils doivent être conformes à l’ordonnance. En cas de substitution, celle-ci doit être sous l’accord du patient ou de sa famille ainsi que du prescripteur.</li>
            <li>Les informations nécessaires concernant la conservation des médicaments nécessitant la chaine de froid doivent être délivrées à la personne présente au comptoir.</li>
            <li>À propos de la législation des produits anesthésiques et des stupéfiants, des explications sont fournies aux nécessiteux.</li>
          </ul>
        </div>
        </div>
      </>
      );
};

      export default PharmacyCard;
