import React from 'react';

import FooterSocialMedia from './FooterSocialMedia';
import { Button, Checkbox, Label, TextInput, Card } from "flowbite-react";
import { motion } from "framer-motion"

function Login() {


  return (
    <>
    <Card>
      <div className="w-1/3 flex justify-center items-center my-4 mx-auto px-4 font-serif">
        <form className="flex max-w-md flex-col gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email1" value="Adresse email" className='justify-center items-center' />
            </div>
            <TextInput id="email1" type="email" placeholder="chuanosiala@gmail.com" required />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password1" value="Votre mot de passe" className='justify-center items-center'/>
            </div>
            <TextInput id="password1" type="password" required />
          </div>
          <div className="flex items-center gap-2">
            <Checkbox id="remember" />
            <Label htmlFor="remember">Remember me</Label>
          </div>
          <motion.button  type="submit" className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-0.5 px-4 border-2 border-green-400 rounded shadow m-1 " whileHover={{ scale: 0.9 }}>
            Connecter
          </motion.button>
        </form>
      </div>
      </Card>
      <FooterSocialMedia />

    </>
  );
}

export default Login;