import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"
const PediatrieCard = () => {
  return (
    <>
      <div className=" service-pediatrie">
        <div>
          <p>Le service de Pédiatrie assure la prise en charge des pathologies médicales de l’enfant de 0 à 15 ans et se divise en 03 Unités.</p>
          <ol>
            <li>
              <h4 className='justify-center  font-bold text-xl'>Urgences pédiatriques :</h4>
              <ul>
                <li>-05 lits pour hospitalisation de jour</li>
                <li>-01 plaque chauffante pour la réanimation néonatale</li>
              </ul>
            </li>
            <li>
              <h4 className='justify-center  font-bold text-xl'>Néonatalogie :</h4>
              <ul>
                <li>-04 couveuses</li>
                <li>-06 lits en salle commune</li>
                <li>-03 lits en salle payante de 3ème catégorie</li>
                <li>-03 lits pour kangourou</li>
              </ul>
            </li>
            <li>
              <h4 className='justify-center  font-bold text-xl'>Pédiatrie générale :</h4>
              <ul>
                <li>-06 lits en salle commune</li>
                <li>-01 lits en salle payante de 1ère catégorie</li>
                <li>-03 lits en salle payante de 3ème catégorie</li>
                <li>-03 lits pour isolement</li>
                <li>-03 lits pour grand enfant</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div>
        <h2 className='justify-center  font-bold text-xl'>Contact : (+261) 38 90 257 71</h2>
      </div>
  
      <div className="offres-service-pediatrie">
                <h3 className='justify-center  font-bold text-xl'>Les offres du service :</h3>
                <ul>
                    <li>Consultations externes (tous les jours à partir de 14h)</li>
                    <li>Visites systématiques</li>
                    <li>Suivi des patients (pour les maladies chroniques)</li>
                    <li>Hospitalisation de jour</li>
                    <li>Hospitalisation</li>
                </ul>
        </div>

        <div className="equipe-travail">
                <h2 className='justify-center  font-bold text-xl'>Équipe de travail :</h2>
                <ul>
                    <li>Dr ANDRIAMANANTENA Manankasina (Chef de service)</li>
                    <li>Mme RAKOTOMALALA Ravo Nambinina F. (Major de service)</li>
                    <li>Mme RATOLOJANAHARY Lalasoa Bodonala (Secrétaire)</li>
                    <li>Dr HARIVAO Hery Anjatiana (Médecin traitant)</li>
                    <li>Dr RASOANANDRIANINA Elicia (Médecin traitant)</li>
                    <li>Mme RASOANOMENJANAHARY Dinaharilaingo Clara Nicole (Infirmière)</li>
                    <li>Mme RABENALISOA Njara Tsilavina (Sage femme)</li>
                    <li>Mme RAHARITIANA Julie Clémentine (Sage femme)</li>
                    <li>Mme ANDRIAMAMPILAMINA Felana Harinantenaina (Sage femme)</li>
                    <li>Mme RAHARINANTENAINA Jacky Laurelle (APH)</li>
                    <li>Mme RAHARIMENJASOA Ravakiniaina (APH)</li>
                    <li>Mr RAKOTONIRINA Hasiniaina Jean Baptiste (APH)</li>
                </ul>
                <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                <ul>
                    <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                    
                </ul>
        </div>

    </>
  );
}

export default PediatrieCard;
