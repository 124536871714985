import { Card } from "flowbite-react";
import { React, useRef, useState } from "react";
import FooterSocialMedia from "./FooterSocialMedia";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_bgrtqir", "template_yn4zpy3", form.current, {
        publicKey: "xb4Tj0nXTI5PjdH7n",
      })
      .then(
        () => {
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 justify-center font-serif">
        <div className="justify-center items-center p-5">
          <Card className="bg-[#c8dee0]">
            <section class="bg-white dark:bg-gray-900">
              <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                  Contactez-nous
                </h2>
                <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                  Vous souhaitez envoyer des commentaires sur nos services ?
                  Besoin de détails sur nos offres de soins ? Faites-le nous
                  savoir.
                </p>
                <form ref={form} onSubmit={sendEmail} class="space-y-8">
                  <div>
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Votre adresse email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="yourmail@gmail.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="subject"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Objet
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Dites-nous comment nous pouvons vous aider"
                      required
                    />
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="message"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      Votre message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows="6"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Laissez un commentaire..."
                    ></textarea>
                  </div>
                  <motion.button
                    className="hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 bg-gray-50 rounded-lg border-2 border-gray-300 shadow-sm mx-auto "
                    whileHover={{ scale: 0.9 }}
                  >
                    Envoyer le message
                  </motion.button>
                </form>
              </div>
            </section>

            <Card>
              <p className="text-sm text-[#0a0a0a] font-semibold">
                Email: chuanosiala@gmail.com
              </p>
              <p className="text-sm text-[#0a0a0a] font-semibold">
                Tél:+261 (34) 49 801 37
              </p>
              <p className="text-sm text-[#0a0a0a] font-semibold">
                Lot II A 16H Anjanamasinana Ambohidratrimo,
              </p>
              <p className="text-sm text-[#0a0a0a] font-semibold">
                Antananarivo 105, Madagascar
              </p>
            </Card>
          </Card>
        </div>
        <div className="md:justify-center items-center p-5">
          <Card className="bg-[#c8dee0] p-5">
            <iframe class="w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[720px]" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=C3C+QF4%20Alakamisy%20Ambohidratrimo%20Antananarivo%20Madagascar+(CHU%20Anosiala)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps trackers</a></iframe>
          </Card>
        </div>
      </div>
      <FooterSocialMedia />
    </>
  );
};

export default Contact;
