import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"
import circuit_anapath from '../assets/circuit_anapath.png';


const divContainerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.25,
        },
    }
}

const divSquareVariants = {
    hidden: { opacity: 0 }, show: { opacity: 1 }
}

const AnapathCard = () => {
    return (
        <>
            <div className="accueil">
                <div>
                    <h2 className='justify-center font-bold text-xl'>ACCUEIL</h2>
                    <p>Le service d’Anatomie et Cytologie Pathologiques est ouvert du Lundi au Vendredi, aux heures de bureau</p>
                </div>
            </div>

            <div className="offre_de-service">
                <div>
                    <h2 className='justify-center font-bold text-xl'>OFFRE DE SERVICE</h2>
                    <ul>
                        <li>-Examen anatomo-pathologique de pièces de biopsies et de pièces opératoires</li>
                        <li>-Frottis cervico-vaginal de dépistage du cancer du col utérin</li>
                        <li>-Cytoponction mammaire</li>
                        <li>-Cytoponction thyroïdienne</li>
                        <li>-Examen cytologique des liquides d’épanchement des séreuses et du LCR</li>

                    </ul>
                </div>
            </div>
            <div className="recommandation">
                <div>
                    <h2 className='justify-center font-bold text-xl'>RECOMMANDATIONS</h2>
                    <ul>
                        <li>-Pour tout examen anatomo-pathologique, se munir de la fiche de liaison et du prélèvement</li>
                        <li>-Pour les patients hospitalisés, se munir du billet d’hôpital</li>
                        <li>-Les prélèvements pour examen anatomo-pathologique doivent être plongés dans du formol dilué à 10 % et mis dans un flacon à goulot large et étanche</li>
                        <li>-Pour les prélèvements osseux, faire suivre les clichés radiographiques</li>
                    </ul>
                </div>
            </div>
            <div className="membre-du-personnel">
                <div>
                    <h2 className='justify-center font-bold text-xl'>PERSONNEL</h2>
                    <h3 className='font-bold'>Médecins :</h3>
                    <ul>
                        <li>Dr RAKOTONDRAINIBE Fenohery Nalisoa (Chef de service)</li>
                        <li>Dr RASOARISOA Eliarivola (Médecin assistant)</li>
                    </ul>
                    <h3 className='font-bold'>Technicien de laboratoire :</h3>
                    <ul>
                        <li>- Mr RAMAMONJISOA Désiré Alexandre</li>
                    </ul>
                    <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                    <ul>
                        <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                    </ul>


                </div>
            </div>
            <div className="ciruit-patient">
                <div>
                    <motion.img whileHover={{ scale: 0.9 }}
                        whileTap={{ scale: 0.9 }} className=" flex-auto px-1 " src={circuit_anapath} alt="hero" />
                </div>
            </div>


        </>
    );
}
export default AnapathCard;
