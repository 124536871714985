import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"


const divContainerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.25,
        },
    }
}

const divSquareVariants = {
    hidden: { opacity: 0 }, show: { opacity: 1 }
}

const TeteEtCouCard = () => {
    return (
        <>
            <div>
                <h2 className='justify-center font-bold text-xl'>OFFRES DE SERVICE</h2>
                <ul>
                    <li>-Neurochirurgie</li>
                    <li>-Otorhinolaryngologie(ORL)</li>
                    <li>-Ophtalmologie</li>
                    <li>-Stomatologie</li>
                </ul>
            </div>
            <div className="offres-service">
                <div>
                    <h2 className='justify-center font-bold text-xl text-[#0b2b2e]'>UNITE DE NEUROCHIRURGIE</h2>
                    <p>
                        -Création en 2019<br />
                        -3ème service de neurochirurgie dans la capitale <br />
                    </p>
                </div>
            </div>

            <div className="unite-neurochirurgie">
                <div>
                    <h2 className='justify-center font-bold text-xl'>ACTIVITES DU SERVICE ET ACTIVITES OPERATOIRES </h2>
                    <h3 className='font-bold'>Activités opératoires : neurochirurgie générale (adulte et pédiatrique)</h3>
                    <ul>
                        <li>-Neurotraumatologie, neurovasculaire , neuroinfectieuse  </li>
                        <li>-Chirurgie du rachis traumatique et dégénérative  </li>
                        <li>-Neurochirurgie tumorale adulte et pédiatrique   </li>
                        <li>-Endoscopie ( chirurgie hypophysaire VB )  </li>
                        <li>-Neurochirurgie pédiatrique /  pathologies malformatives  </li>
                    </ul>

                    <h3 className='font-bold'>Activités service : soins , visites , encadrement  </h3>
                    <ul>
                        <li>-2022 : 103 interventions neurochirurgicales dont 40 % URGENCE</li>
                        <li>-2023 : 125 interventions neurochirurgicales </li>
                        <li>-Neurotraumatologie : 30 %</li>
                        <li>-Chirurgie pédiatrique : 22 %</li>
                        <li>-Chirurgie rachis : 18 %</li>
                        <li>-Neurovasculaire : 15 % </li>
                        <li>-Tumeurs / endoscopie : 10%</li>
                        <li>-Malformations : 5 %  </li>
                    </ul>
                </div>
            </div>

            <div className="offres-service">
                <div>
                    <h2 className='justify-center font-bold text-xl text-[#0b2b2e]'>UNITE OPHTALMOLOGIE</h2>
                    <p><strong>LES SPECIALITES DU SERVICE</strong></p>
                    <p>
                        L’unité Ophtalmologie du CHU Anosiala assure la prise en charge des diverses pathologies du globe oculaire et de ses annexes. Avec nos deux ophtalmologistes sur place, le Dr ANDRIAMBELO Ravaka Hariniaina et le Dr RANDRIANARIVELO Harinarivo Prosper, l’unité s’engage à vous prodiguer des examens de la vue simple aux consultations spécialisées en fonction de votre motif de consultation.
                    </p>
                    <h3 className='font-bold'>OFFRES DE SERVICE</h3>
                    <ul>
                        <li>-La réfraction en vue d’une prescription de verres correcteurs et des lunettes de lecture</li>
                        <li>-La prise en charge des pathologies traumatiques du globe oculaire, les paupières et de l’orbite</li>
                        <li>-La prise en charge de la cataracte et des luxations du cristallin</li>
                        <li>-Les pathologies infectieuses et inflammatoires du segment antérieur de l’œil : conjonctivites, ptérygions, plaies et abcès de cornée, …</li>
                        <li>-Les pathologies des paupières, des voies lacrymales, de l’orbite</li>
                        <li>-Le dépistage et suivi du glaucome</li>
                        <li>-Les uvéites, choriorétinites, occlusions vasculaires et névrites optiques</li>
                        <li>-Lésions ophtalmologiques d’origine diabétique</li>
                        <p>-Nous privilégions la chirurgie ambulatoire pour optimiser le confort des patients. </p>
                    </ul>
                    <p>En dehors des horaires de travail et les week-ends, vous pouvez passer au service des urgences générales pour les urgences ophtalmologiques (traumatisme oculaire, œil rouge douloureux, baisse brutale de la vision). Les ophtalmologistes de l’hôpital sont toujours d’astreintes et seront connecté en permanence avec le service des urgences générales.</p> <br />
                    <p>Le service travaille également avec des partenaires qui assurent l’équipement de l’unité afin de promouvoir la prise en charge des pathologies oculaire et d’alléger les coûts liés aux soins. </p>

                </div>
            </div>

            <div className="service-orl">
                <div>
                    <h2 className='justify-center font-bold text-xl text-[#0b2b2e]'>UNITE OTORHINOLARYNGOLOGIE (ORL)</h2>
                    <p><strong>LES SPECIALITES DU SERVICE</strong></p>
                    <p>
                        L’ORL est une discipline médico-chirurgicale qui s’occupe des pathologies de l’oreille, du nez et des sinus, du cou, des voies aéro-digestives supérieures.
                    </p>
                    <h3 className='font-bold'>OFFRES DE SERVICE</h3>
                    <ul>
                        <li>-Consultation et suivi : pathologies médicales de l’oreille, du nez et des sinus, du cou, des voies aéro-digestives supérieurs</li>
                        <li><strong>Explorations disponibles : </strong></li>
                        <li>-Endoscopie nasale au tube rigide (adulte et grand enfant)</li>
                        <li>-Cytoponction échoguidé (nodule thyroïdien) en collaboration avec le service de radiologie</li>
                        <li>-Cytologie nasale : en collaboration avec le service de biologie</li>
                        <li>-Chirurgies : chirurgie des masses cervico-faciales, amygdalectomie adénoïdectomie </li>
                        <li>-Petites chirurgies diverses : biopsie, parage des plaies, chirurgie de cicatrices hypertrophiques et chéloïdes, réduction de fracture des os du nez …</li>
                        <li>-A noter : en l’absence de matériels et de personnels médicaux les chirurgies endonasales, otologiques, du larynx ne sont pas encore disponibles</li>

                    </ul>
                </div>
            </div>

            <div className="service-stomatologie">
                <div>
                    <h2 className='justify-center font-bold text-xl text-[#0b2b2e]'>UNITE STOMATOLOGIE</h2>
                    <h3 className='font-bold'>OFFRES DE SERVICE</h3>
                    <p><strong>Soins Conservateurs :</strong></p>
                    <ul>
                        <li>-Pansement dentinaire</li>
                        <li>-Meulage d’émail</li>
                    </ul>

                    <p><strong>Soins Endodontiques :</strong></p>
                    <ul>
                        <li>-Fistule dentaire</li>
                        <li>-Kyste péri-apicale</li>
                        <li>-Granulome</li>
                        <li>-Mortification pulpaire</li>
                        <li>-Pulpite (Nécropulpecotmie, Biopupectomie)</li>
                    </ul>

                    <p><strong>Extraction dentaire :</strong></p>
                    <ul>
                        <li>-Débris radiculaire</li>
                        <li>-Dents surinfectées</li>
                        <li>-Mobilité dentaire suivant le degré</li>
                    </ul>

                    <p><strong>Soins prophylactiques :</strong></p>
                    <ul>
                        <li>-Fluoration</li>
                    </ul>

                    <p><strong>Soins parodontaux :</strong></p>
                    <ul>
                        <li>-Curetage gingivale</li>
                        <li>-Décapuchonnage</li>
                        <li>-Refroidissement desmodontale</li>
                    </ul>
                    <p><strong>Détartrage + surfaçage radiculaire + polissage </strong></p>

                    <h3 className='font-bold'>RECOMMANDATIONS</h3>
                    <p><strong>Pour les extractions:</strong></p>
                    <ul>
                        <li>-De préférence dans la matinée</li>
                        <li>-Si patient Diabétique : se munir de l’autorisation de son Médecin traitant (Diabétologue)</li>
                        <li>-Si patient sous Aspégic : arrêter de prendre le médicament 2 jours avant avulsion avec autorisation de médecin traitant</li>
                        <li>-Manger avant d’aller à l’hôpital</li>
                    </ul>

                    <p><strong>Pour les Handicapés :</strong></p>
                    <ul>
                        <li>-Prise en charge nécessairement sur rendez-vous</li>
                    </ul>

                    <p><strong>CIRCUIT à suivre pour le patient</strong></p>
                    <ul>
                        <li>-S’adresser au secrétariat pour l’enregistrement </li>
                        <li>-Passer chez l’Odontostomatologiste pour la consultation et le soin </li>
                        <li>-Repasser au secrétariat pour la signature de l’Avis de Somme à Payer</li>
                        <li>-Descendre à la Cession pour le paiement</li>
                        <li>-Repasser au secrétariat pour prendre un Rendez-vous</li>
                    </ul>
                    <p><strong>Conditions :</strong></p>
                    <ul>
                        <li>-Se munir d’un mouchoir </li>
                        <li>-Ne pas mettre du rouge à lèvre pour les femmes </li>
                    </ul>

                    <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                    <ul>
                        <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>

                    </ul>
                </div>
            </div>
        </>
    );
}
export default TeteEtCouCard;