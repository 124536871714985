import React from 'react';
import { Container, Footer, Button, SocialMedia } from 'flowbite-react';
import { BsBadgeHdFill, BsDribbble, BsFacebook, BsFillHospitalFill, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";
import logo_chu from '../assets/Logo.png'
import logo_msanp from '../assets/LOGO-MSANP-VF.ico';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import VisitCounter from './VIsitCounter';
import { GiFamilyHouse } from 'react-icons/gi';

const FooterSocialMedia = () => {
    return (

        <motion.footer
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-[#026d77] text-[#fafafa] py-8"
        >
            <div className="container mx-auto text-center">
                <div className="container mx-auto text-center text-[#211f1f]">
                    <Footer.Copyright className="text-red-500 font-bold" href="#" by=" CHU Anosiala™" year={2024} />
                </div>
                <div className="mt-4 md:mt-0">
                    <ul className="flex justify-center space-x-4 mt-2 text-[#2C2C2C] font-semibold">
                        {/* <motion.li className="" whileHover={{ scale: 0.9 }}> */}
                        {/* <VisitCounter className='mr-auto'></VisitCounter> */}
                        {/* </motion.li> */}
                      
                        <Footer.Icon className="hover:underline" href="https://www.facebook.com/chuanosial.chua.7" icon={BsFacebook} />
                        <Footer.Icon className="hover:underline" href="https://www.facebook.com/chuanosial.chua.7" icon={BsInstagram} />
                        <Footer.Icon className="hover:underline" href="https://www.facebook.com/chuanosial.chua.7" icon={BsTwitter} />
                        <Footer.Icon className="hover:underline" href="https://www.msanp.gov.mg/" icon={GiFamilyHouse}/>
                    </ul>
                 
                </div>
            </div>
        </motion.footer>
    );
};

export default FooterSocialMedia;