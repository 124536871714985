import { Carousel } from "flowbite-react";
import { useState,useRef,useEffect  } from "react";
import { getImageUrl, isImage} from "../shared/Utils";
import laboratoire from "../assets/video/laboratoire.mp4";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export function Transition(props) {
  
  if (isImage(props.url[0])) {
    return (
      <Carousel slideInterval={props.interval} className="rounded-xl">
        {props.url.map((url) => (
          <img className="object-contain  h-full w-auto " src={require('../assets/' + url)} alt="..." bordered />
        ))}
      </Carousel>
    );
  }
  else {
    return (
      <Carousel slideInterval={360000} className="rounded-xl">
        {props.url.map((url) => (
          <video  className="object-contain  h-full w-full " src={require('../assets/' + url)} controls></video>
          
        ))
        }
      </Carousel>
    );
  }

}


