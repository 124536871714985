import React from 'react';
import { Card} from 'flowbite-react';
import { motion } from "framer-motion"

const LaboratoireCard = () => {
  return (
   <> 
    <div className="container">
        <h3 className=' justify-center font-bold text-xl' >Contact: 038 36 457 18</h3>
        <h3 className=' justify-center font-bold text-xl' >OFFRES DE SERVICE</h3>
        <ul>
            <li>-Prélèvement des patients en externe</li>
            <li>-Réalisation des analyses de biologie médicale</li>
            <li>-Accueil et réception au don de sang</li>
            <li>-Délivrance de poche de sang</li>
        </ul>
   
    <div className="opening-hours">
                <h3 className='justify-center font-bold text-xl'>HORAIRES D'OUVERTURE</h3>
                <p>Laboratoire ouvert 7j/7, 24h/24 avec :</p>
                <ul>
                    <li className=''>Service de routine : lundi au vendredi de 8h à 16h</li>
                    <li className=''>Service de garde : lundi au vendredi de 16h à 8h et week-end, jour férié</li>
                </ul>
                <h4 className='font-bold'>Horaires de réception de :</h4>
                <ul>
                    <li className='font-bold'>Prélèvements en externe</li>
                    <ul>
                        <li className=''>Lundi au vendredi à partir de 7h30 – 9h30</li>
                    </ul>
                    <li className='font-bold'>Réception des échantillons</li>
                    <ul>
                        <li className=''>-Bilans de routine en hospitalisation : lundi – vendredi : de 7h à 15h</li>
                        <li className=''>-Bilans à caractère urgent : 24h/24, 7j/7</li>
                    </ul>
                    <li className='font-bold'>Donneurs de sang</li>
                    <ul>
                        <li className=''>-Lundi au vendredi : 9h – 15h</li>
                    </ul>
                    <li className='font-bold'>Délivrance de poche</li>
                    <ul>
                        <li className=''>- 7jr/7, 24H/24</li>
                    </ul>
                </ul>
        </div>
          <h3 className=' justify-center font-bold text-xl'>BILANS DISPONIBLES</h3>
                <table className='border-collapse border border-slate-400 justify-center'>
                    <thead>
                        <tr>
                            <th className='border border-slate-300'>SECTEUR</th>
                            <th className='border border-slate-300'>BILANS FOURNIS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border border-slate-300'>Hématologie</td>
                            <td className='border border-slate-300'>NFS, VSH, Taux de réticulocytes, Bilan d’hémostase</td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Immuno-hématologie</td>
                            <td className='border border-slate-300'>Groupage sanguin</td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Biochimie sanguine</td>
                            <td className='border border-slate-300'>
                                Bilan rénal : Urée, Créatinine<br/>
                                Bilan hépatique : ASAT/ALAT, GGT, PAL, Bilirubinémie totale/directe<br/>
                                Electrolytes : ionogramme sanguin, magnésium, calcium, phosphore<br/>
                                Protéines totales, albumine<br/>
                                Bilan lipidique : Triglycérides, Cholestérol total, HDL-C, LDL-C<br/>
                                Bilan métabolique : Acide urique, Glycémie, …<br/>
                                Bilan inflammatoire : CRP<br/>
                                Pancréas : Lipase
                            </td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Biochimie urinaire</td>
                            <td className='border border-slate-300'>Examen à la Bandelette Urinaire, Protéinurie de 24h</td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Biochimie des liquides de ponction</td>
                            <td className='border border-slate-300'>
                                Examen cytochimique de :<br/>
                                - Liquide d’ascite<br/>
                                - Liquide pleural<br/>
                                - Liquide cérébro-spinal<br/>
                                - Autres
                            </td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Immunologie</td>
                            <td className='border border-slate-300'>Sérologie de : Hépatite B, C, HIV, RPR</td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Parasitologie</td>
                            <td className='border border-slate-300'>Goutte épaisse – frottis mince</td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Bactériologie</td>
                            <td className='border border-slate-300'>
                                Examen cytobactériologique des :<br/>
                                Urines (ECBU),<br/>
                                LCR, liquides de ponction,<br/>
                                Pus et matériels,<br/>
                                Hémoculture,<br/>
                                FCV, PV,<br/>
                                GeneXpert Mycobacterium tuberculosis/RIF pour le diagnostic de la tuberculose et résistance à la Rifampicine,<br/>
                                Examen des crachats à la recherche de BAAR (Ziehl-Neelsen)
                            </td>
                        </tr>
                        <tr>
                            <td className='border border-slate-300'>Virologie</td>
                            <td className='border border-slate-300'>GeneXpert SARS-Cov-2</td>
                        </tr>
                    </tbody>
                </table>
    </div>

</>
  );
};

export default LaboratoireCard;