import { motion } from "framer-motion"

import { Transition } from "./Transition";
import FooterSocialMedia from "./FooterSocialMedia";
import { useState } from "react";
import { image_carousel ,video_carousel} from "../shared/Consts";


function Actualite() {

  const [data, setData] = useState(video_carousel);
  return (
    <>
      <div class="flex flex-col justify-center">
        <div className="flex flex-row justify-center">
          <motion.button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-0.5 px-4 border-2 border-[#3e8f97] rounded shadow m-3 " whileHover={{ scale: 0.9 }} onClick={() => { setData(image_carousel);}}>
            Images
          </motion.button>
          <motion.button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-0.5 px-4 border-2 border-[#3e8f97] rounded shadow m-3 " whileHover={{ scale: 0.9 }} onClick={() => { setData(video_carousel);}}>
            Vidéos
          </motion.button>
        </div>
        <div className="w-full h-dvh justify-center items-center my-5 mx-auto px-4 font-serif bg-gradient-to-r from-zinc-50 to-[#d8e7e8]">
          <Transition url={data} interval ={6000}>
          </Transition>
        </div>

      </div>
      <FooterSocialMedia />
    </>

  )
}

export default Actualite;