
import React from 'react';
import { Carousel } from "flowbite-react";
import chu_anosiala_drone from '../assets/chu_anosiala_drone.png';
import chu_anosiala_gp from '../assets/chu_anosiala_gp.png';
import de_photo from '../assets/de_photo.png';

import chu_anosiala_img1 from '../assets/caroussel/chu-anosiala_img1.png';
import chu_anosiala_img2 from '../assets/caroussel/chu-anosiala_img2.png';
import chu_anosiala_img3 from '../assets/caroussel/chu-anosiala_img3.png';
import chu_anosiala_img4 from '../assets/caroussel/chu-anosiala_img4.png';
import chu_anosiala_img5 from '../assets/caroussel/chu-anosiala_img5.png';
import chu_anosiala_img6 from '../assets/caroussel/chu-anosiala_img6.png';

import { motion } from "framer-motion"
import FooterSocialMedia from './FooterSocialMedia';
import { Transition } from "./Transition";


const title_text = "Centre Hospitalier Universitaire Anosiala".split(" ");



function Accueil1() {

  const sloganVariants = {
    hidden: { opacity: 0, y: 500 },
    visible: { opacity: 1, y: 0 },
  };


  return (
    <>

      <div className="p-0">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 justify-center font-serif">

          <div className=" p-0 bg-white text-white hidden md:flex justify-center ">
            <div style={{ width: '100%', height: '300px' }}>
              <Carousel autoPlay infiniteLoop className='justify-center'>
                <img src={chu_anosiala_img1} alt="Slide 1" />
                <img src={chu_anosiala_img2} alt="Slide 2" />
                <img src={chu_anosiala_img3} alt="Slide 3" />
                <img src={chu_anosiala_img4} alt="Slide 4" />
                <img src={chu_anosiala_img5} alt="Slide 5" />
                <img src={chu_anosiala_img6} alt="Slide 6" />
              </Carousel>
            </div>

          </div>

          {/* Exemple d'éléments */}
          <div className="bg-white p-4 text-white">
            <div className='py-20 flex flex-col justify-center items-center md:py-10'>
              <img
                alt="directeur image"
                height="200"
                src={de_photo}
                width="200"
                className="item mb-3 rounded-full shadow-lg justify-center items-center bg-gradient-to-r from-zinc-50 to-[#d8e7e8]"
              />
              <h5 className=" text-sm mb-1 md:text-xl font-medium text-gray-900 dark:text-white justify-items-center"><strong>Prof. Tianarivelo RAJAONARIVONY</strong></h5>
              <span className="text-sm text-gray-900 dark:text-gray-400">Directeur d'établissement</span>
            </div>


            <div>
              <motion.h1 className="text-xl pb-5  font-bold text-center text-red-400"
                initial="hidden"
                animate="visible"
                variants={sloganVariants}
                transition={{ duration: 1 }}
              >
                "Ensemble pour une vie continuellement saine"
              </motion.h1>
              <h1 className=" text-sm md:text-xl lg:text-2xl font-bold text-center text-[#191d21] py-0">
                {title_text.map((el, i) => (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.1,
                      delay: i / 10
                    }}
                    key={i}
                  >
                    {el}{" "}
                  </motion.span>
                ))}
              </h1>
              <div className='px-0 md:px-20'>
                <p className="text-xs indent-5 md:text-base lg:text-xl my-2 md:indent-10 text-justify text-[#191d21]">
                  Les équipes médicales et l’ensemble du personnel du Centre Hospitalier Universitaire d’Anosiala (CHU) vous souhaitent la bienvenue et mettent à votre disposition leurs compétences avec l’appui d’équipements de diagnostics et de traitements parmi les plus modernes de notre pays.
                </p>
                <p className="text-xs indent-5 marker:md:text-base lg:text-xl my-0 md:indent-10 text-justify text-[#191d21]">
                  De nombreux patients, d’origines et de nationalités différentes, viennent se soigner au CHU d’Anosiala chaque année. Cette attractivité repose non seulement sur la prise en charge de pathologies lourdes, de maladies rares qui nécessitent des compétences et des équipements spécifiques mais aussi sur l’environnement au sein de notre Hôpital. Le CHU d’Anosiala bénéficie également de la coopération avec l’équipe médicale chinoise mais reste ouvert à d’autres partenariats afin de répondre aux besoins de la population en termes d’offre de soins et de confort pendant leur séjour.
                </p>
                <p className="text-xs indent-5 md:text-base lg:text-xl my-0 md:indent-10 text-justify text-[#191d21]">
                  Les différentes spécialités médicales disponibles dans notre Hôpital sont ainsi présentées dans ce livret. Cette présentation conçue à votre attention vous permet de faire connaissance avec le CHU d’Anosiala. Les professionnels hospitaliers qui l’ont réalisé se sont efforcés de vous donner les informations qui contribuent au bon déroulement de votre séjour.
                </p>
                <p className="text-xs indent-5 md:text-base lg:text-xl my-0 md:indent-10 text-justify text-[#191d21]">
                  Notre Hôpital, en tant que CHU et en partenariat avec les Facultés de Médecine, participe à la formation des médecins et des paramédicaux. Nous nous efforçons également d’assurer une formation continue afin de disposer d’un personnel qualifié et à jour sur leurs connaissances.
                </p>
                <p className="text-xs indent-5md:text-base lg:text-xl my-0 md: indent-10 text-justify text-[#191d21]">
                  La qualité de l’accueil, de votre prise en charge, ainsi que la sécurité des installations, sont pour nous des préoccupations constantes. Dans cet esprit, vous nous fournirez une aide précieuse en nous faisant part de vos appréciations, critiques et ou suggestions.
                </p>
                <p className="text-xs indent-5 md:text-base lg:text-xl my-0 md:indent-10 text-justify text-[#191d21]">
                  Bref, les efforts de l’ensemble du personnel hospitalier du CHU d’Anosiala sont tournés vers la qualité et la performance d’un service public de santé au bénéfice de la population.
                </p>
                <p className="text-xm font-thin md:text-base lg:text-xl my-2 text-center text-[#191d21] ">
                  Bon séjour et prompt rétablissement !
                </p>
              </div>
            </div>

          </div>


          <div className="text:xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 justify-items-center font-semibold md:text-2xl md:p-10">
            <p>Téléphone : (+261) 38 78 051 42</p>
            <p>E-mail : chuanosiala@gmail.mg</p>
          </div>



        </div>
      </div>
      <FooterSocialMedia />

    </>
  );
}



export default Accueil1;
