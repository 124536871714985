import React from 'react';
import FooterSocialMedia from './FooterSocialMedia';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion";
import organigrame from '../assets/organigrame.jpg';

function About() {


  return (
    <>
      <Card>
        <div className="w-5/6 flex flex-col justify-center items-center my-4 mx-auto px-4 font-serif">

          <div className="w-5/6 justify-center items-center p-5">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className='mt-2'>
              <motion.img whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1.1 }} className=" flex-auto px-1" appearance-auto src={organigrame} alt="hero" />
            </motion.div>
          </div >
        </div>
      </Card>
      <FooterSocialMedia />

    </>
  );
}

export default About;