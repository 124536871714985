import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"


const divContainerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
    },
  }
}

const divSquareVariants = {
  hidden: { opacity: 0 }, show: { opacity: 1 }
}

const AtuCard = () => {
  return (
    <>
      <div>
          <h3 className='justify-center font-bold text-xl'>ACCUEIL</h3>
        <p>
          <strong>Contact: 038 70 084 62</strong><br />
          <strong>Horaire:</strong> ouvert 24 h/24 h et 7 jours/7<br />
          <strong>Capacité:</strong><br />
          2 box d’accueil<br />
          1 salle de déchocage à 2 lits<br />
          1 salle de lit porte à 6 lits<br />
          1 salle de soins
        </p>
      </div>
      <div className="offres-service">
        <div>
          <h2 className='justify-center font-bold text-xl'>OFFRES DE SERVICE</h2>
          <p>
          Toutes pathologies médicales, chirurgicales, traumatologiques chez l’adulte<br />
          Toutes pathologies d’origine traumatiques en pédiatrie<br />
          Consultation externe chez l’adulte
          </p>
        </div>
      </div>

      <div className="membres-personnel">
        <div>
          <h2 className='justify-center font-bold text-xl'>MEMBRES DU PERSONNEL</h2>
          <h3 className='font-bold'>Médecins :</h3>
          <ul>
            <li>Anesthésiste Réanimateur : Docteur RANDRIANAMBININA Hajanirina (Chef de service)</li>
          </ul>
          <h3 className='font-bold'>Urgentistes :</h3>
          <ul>
            <li>Docteur RABESON Tahiana</li>
            <li>Docteur RAJAONARISON Perle</li>
          </ul>

          <h3 className='font-bold'>Paramédicaux :</h3>
          <ul>
            <li>RAMANANARIVO Mirana (Major de service)</li>
            <li>RAMAROSANDRATANA Joel</li>
            <li>ANDRIAMASIMANANA Nambinintsoa</li>
            <li>ANDRIAMANATSARA Charlesya</li>
            <li>RAJOELIMANANA Jenny</li>
          </ul>
          <h3 className='font-bold'>APH ET Brancardiers :</h3>
          <ul>
            <li>RAFARALAHY Jean Eric</li>
            <li>RAZAFIMAHATRATRA Mamisoa</li>
            <li>RAVELOMANANTSOA Felana</li>
            <li>ANDRIANIRINA Jeanson</li>
          </ul>
          <h3 className='font-bold'>Ambulanciers :</h3>
          <ul>
            <li>RAKOTONIRINA Herimalala</li>
            <li>RALISON Jeremia</li>
          </ul>
          <h3 className='font-bold'>Formation - Stage - recherche :</h3>
          <ul>
            <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>

          </ul>
        </div>
        </div>
    </>
  );
}
export default AtuCard;
