import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"


const divContainerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
    },
  }
}

const divSquareVariants = {
  hidden: { opacity: 0 }, show: { opacity: 1 }
}


const MtcCard = () => {
    return (
        <>
        <div>
          <h3 className='justify-center font-bold text-xl'>Accueil</h3>
          <p className="card-text">
          Au sein du CHU d'Anosiala, la Médecine Traditionnelle Chinoise (MTC) a trouvé sa place comme une spécialité reconnue, notamment à travers la pratique de l'acupuncture. 
          Cette technique millénaire vise à rétablir l'équilibre énergétique du corps en stimulant des points spécifiques. Elle est intégrée dans l'approche thérapeutique du CHU 
          pour compléter les traitements médicaux conventionnels.
        </p>

        <p className="card-text">
          Les professionnels de la santé utilisent l'acupuncture pour traiter une variété de conditions, allant de la gestion de la douleur chronique à l'anxiété, en passant par des troubles digestifs.
        </p>
        <p className="card-text">
          En intégrant la MTC dans le cadre hospitalier, le CHU d'Anosiala offre une approche holistique des soins, permettant aux patients de bénéficier d'un accompagnement personnalisé 
          qui prend en compte à la fois les aspects physiologiques et psychologiques de leur santé. Une séance d'acupuncture au CHU Anosiala dure environ 30 minutes.
        </p>
        <h3 className='justify-center font-bold text-xl'>Offres de service</h3>
        <ul>
          <li>-Acupuncture, médecine traditionnelle chinoise)</li>
          <li>-Chirurgie générale</li>
          <li>-Médecine générale</li>
          <li>-Gynécologie</li>
          <li>-Radiologie</li>
          <li>-Laboratoire biologique</li>
          <li>-Anesthésie</li>
          <li>-Pharmacie</li>
        </ul>
        </div>
        
      </>
    );
};

export default MtcCard;