import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"
function ReaCard() {
    return (
        <>
            <div className="reanimation">
                <h3 className=''><strong>Hospitalisation</strong></h3>
                <p>-3ème bâtiment au rez-de-chaussée à droite</p>
                <h3 className=''><strong>Déscription des chambres</strong></h3>
                <p>-9 chambres avec 33 lits :</p>
                <ul>
                    <li>-01 chambre de 1ère catégorie (01 lit pour le patient, 01 lit pour le garde malade)</li>
                    <li>-05 chambres de 3ème catégorie (03 lits)</li>
                    <li>-02 chambres de 4ème catégorie ou salle commune (06 lits)</li>
                </ul>
            </div>

            <div className="offre-service-reanimation ">
                <h2><strong>Contact : 038 80 185 61</strong></h2>
                <h2><strong>Offre de service</strong></h2>
                <ul>
                    <li>-Réanimation médicale et chirurgicale (24h/24h et 7j/7j)</li>
                    <li>-Consultation externe (tous les mardis)</li>
                    <li>-Consultation pré-anesthésique</li>
                </ul>
            </div>

            <div className="pathologies-frequentes">
                <h2><strong>Les pathologies les plus fréquentes</strong></h2>
                <ul>
                    <li>-Accident vasculaire cérébral</li>
                    <li>-Coma éthylique</li>
                    <li>-Coma diabétique</li>
                    <li>-Hémorragie digestive</li>
                    <li>-État de choc chez les patients post-opératoires</li>
                    <li>-Traumatisme crânien grave</li>
                    <li>-Polytraumatisme</li>
                </ul>
            </div>

            <div className="equipes-reanimation">

                <h2><strong>ÉQUIPES</strong></h2>
                <h4><strong>Médecin Anesthésiste Réanimateur :</strong></h4>
                <ul>
                    <li>-Dr RANDRIANAMBININA Hajanirina (Chef de service)</li>
                    <li>-Dr RALINIRINA Volazara Jacintha</li>
                    <li>-Dr RASOLOMALALA Lova Princia</li>
                </ul>
                <h4><strong>Médecin traitant :</strong></h4>
                <ul>
                    <li>-Dr RAJAONERA Tahinaniana Solomon</li>
                    <li>-Dr RAKOTONDRAVONY Jean Jules Antonio</li>
                </ul>
                <h3><strong>Paramédicaux</strong></h3>
                <ul>
                    <li>-RAKOTONIRINA Jocelyn Bertrand Nomenjanahary (Major de service)</li>
                    <li>-ANDRIANIAINA Rochelle</li>
                    <li>-RASOAMBOAHANGY Ngoronaniaina Adeline</li>
                    <li>-HARIMAMY Nantenaina Valérie</li>
                </ul>
                <h3><strong>Secrétaire</strong></h3>
                <ul>
                    <li>- ANDRIANIARIOELINA Maholitiana</li>
                </ul>
                <h3><strong>Agent de propreté et d’hygiène</strong></h3>
                <ul>
                    <li>-RAVELONIAVONJOANY Alison Rado</li>
                    <li>-SITRAPITIAVANA Landy Fiononantsoa</li>
                    <li>-RAZAFIMAHATRATRA Raymond</li>
                </ul>
                <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                <ul>
                    <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                </ul>
            </div>
        </>
    );
}
export default ReaCard;
