import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"
const ImagerieCard = () => {
    return (
        <>
            <div className="service-imagerie">
                <p>Le service d'imagerie offres des services de diagnostic médical utilisant diverses techniques d'imagerie.</p>
                <h3 className=' justify-center font-bold text-xl'>Contact : 038 45 005 72</h3>
                <h3 className=' justify-center font-bold text-xl'>Offre de service :</h3>
                <ul>
                    <li>-Radiographie conventionnelle standard</li>
                    <li>-Echographie et Doppler : sauf cardiaque</li>
                    <li>-Ponction sous-guidage échographique</li>
                    <li>-Scanner sauf angioscanner</li>
                </ul>
            </div>

            <div className="radiographie-standard">
                <h2 className='justify-center font-bold text-xl list-disc'>Radiographie standard</h2>
                <ul>
                    <li><strong>-Contre-indication :</strong></li> 
                    <li>Grossesse (1er trimestre : contre-indication absolue ; 2 et 3e trimestres : à discuter avec le médecin prescripteur, évaluer le rapport bénéfice/risque)</li>
                    <li><strong>-Pas de préparation spécifique</strong></li>
                    <li><strong>-Mise en condition :</strong></li>
                    <li> Pansement (plaie ouverte), stabilisation de l’état hémodynamique (patient en état de choc), transport (polytraumatisme et traumatisme vertébro-médullaire)</li>
                    <li><strong>-Rendu de l’examen :</strong></li> 
                    <li>Après l’examen</li>
                </ul>
            </div>

            <div className="echographie">
                <h2 className='justify-center font-bold text-xl list-disc'>Echographie</h2>
                <h3><strong>Patients hospitalisés :</strong></h3>
                <ul>
                    <li>Mise en condition :</li>
                    <ul>
                        <li>-Stabilisation de l’état hémodynamique avant l’échographie (patient en état de choc), OU échographie au lit du patient en cas d’instabilité hémodynamique seulement</li>
                        <li>-Transport (polytraumatisme et traumatisme vertébro-médullaire)</li>
                        <li>-Mentionner dans les renseignements cliniques les maladies à risque de contamination connue (hépatite virale, HIV, COVID, tuberculose…)</li>
                    </ul>
                </ul>
                <h3><strong>Patients externes :</strong></h3>
                <p>-Prise de rendez-vous conseillée au 0342214998</p>
                <h3>-Préparation nécessaire pour chaque type d’examen :</h3>
                <ul>
                    <li>-Echographie abdominale : à jeun d’au moins 3 heures si exploration focalisée sur les voies biliaires</li>
                    <li>-Echographie pelvienne ou prostatique : vessie pleine nécessaire, sonde vésicale à clamper</li>
                    <li>-EchoDoppler des artères rénales : à jeun</li>
                    <li>-Pour les autres examens : pas de préparation spécifique</li>
                </ul>
                <p><strong>Rendu de l’examen :</strong> Après l’examen</p>
            </div>

            <div className="scanner">
                <div>
                    <h2 className='justify-center font-bold text-xl list-disc'>Scanner</h2>
                    <ul>
                        <li><strong>Renseignement à l’accueil du service :</strong></li>
                        <li>Tarification, informations et consignes nécessaires, vérification des contre-indications par les manipulateurs en électroradiologie (pour les scanners avec injection de produit de contraste iodé surtout : insuffisance rénale, allergies, biguanides, grossesse)</li>
                        <li><strong>Préparation :</strong> </li>
                        <li>Selon le type d’examen</li>
                        <li><strong>Réalisation de l’examen :</strong></li> 
                        <li>Protocole par le radiologue, acquisition des images</li>
                        <li><strong>Pour les enfants :</strong>
                            <ul>
                                <li>-Âgés de 5 ans et plus (les parents doivent les rassurer, les expliquer avant l’examen pour que l’enfant ne bouge pas pendant l’examen)</li>
                                <li>-Âgés de 2 à 5 ans (la maman peut se mettre sur la table d’examen avec l’enfant pendant l’examen pour le tenir et le rassurer)</li>
                                <li>-Âgés de moins de 2 ans (valium par voie rectale conseillée, consultation chez un pédiatre)</li>
                                <li>-Si enfant très agité ou échec des autres moyens sus-décrites (sédation, consultation chez un réanimateur anesthésiste)</li>
                            </ul>
                        </li>
                        <li><strong>Rendu de l’examen :</strong>
                            <ul>
                                <li>-La veille de l’examen à partir de 14 heures : pour les externes et hospitalisés dans les services autres que la réanimation</li>
                                <li>-Après 3 heures de l’examen (heures de travail) et dans la matinée pour les examens pendant la garde de nuit : patients des urgences et de la réanimation</li>
                                <li>-Lundi à partir de 14 heures : pour les examens du week-end</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="equipes-service">
                <h2 className='justify-center font-bold text-xl list-disc'>Nos équipes</h2>
                <h3><strong>Chef de service</strong></h3>
                <p>-Docteur RASOLOHERY Honjaniaina (radiologue)</p>
                <h3><strong>Major du service</strong></h3>
                <p>-Monsieur RAMIANDRISOA Jacquis Clairan (manipulateur en électroradiologie)</p>
                <h3><strong>Manipulateurs en électroradiologie</strong></h3>
                <ul>
                    <li>-Madame RALALANIRINA Viviane</li>
                    <li>-Madame HALISOAMALALA Larissa</li>
                    <li>-Monsieur HERINARIVORANDRIANANDRASANA Pascal</li>
                    <li>-Monsieur RAVONIHARISON Maminirina Sitraka</li>
                    <li>-Monsieur RANDRIANINA Mamitiana</li>
                </ul>
                <h3><strong>Secrétaire du service</strong></h3>
                <p>-Madame HANITRINIANA Fanomezantsoa Angèle</p>
                <h3><strong>Agent d’appui</strong></h3>
                <p>-Monsieur RAKOTONDRAFARA Jean Nirina</p>

                <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                <ul>
                    <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                </ul>
            </div>
        </>
    );
};

export default ImagerieCard;