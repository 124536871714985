export function getImageUrl(url) {
    return (
      '../assets/' +url +'.JPG'
    );
  }

  export function isImage(fileName){
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'webm'];
  
    const extension = fileName.split('.').pop().toLowerCase();
  
    if (imageExtensions.includes(extension))  return true;
    else return false;
  };