import React from 'react';
import { Card } from 'flowbite-react';
import { motion } from "framer-motion"

const MedecineCard = () => {
    return (
        <>
            <div>
                <h6 className="md:text-xl font-semibold text-gray-800 dark:text-gray-300 md:mb-2">Contact : 038 81 481 15</h6>
                <h6 className="md:text-xl font-semibold text-gray-800 dark:text-gray-300 ">Offres de service</h6>
                <ul className="md:pl-5 md:mb-4">
                    <li>Consultation externe : 14 heures à 18 heures (7/7)</li>
                    <li>Hospitalisation : Tous les jours (7/7)</li>
                </ul>
                <h6 className="text-xl font-semibold text-gray-800 dark:text-gray-300">Les activités hospitalières</h6>
                <h6 className="text-sm md:text-lg md:font-semibold text-gray-700 dark:text-gray-400">
                    Les Pathologies Les Plus Fréquentes :
                </h6>
            </div>
            <div>
                <ul className="">
                    <li>Médecine interne :</li>
                    <ul className="">
                        <li>-Affections hématologiques malignes ou bénignes (en collaboration avec les centres de référence en oncologie médicale)</li>
                        <li>-Maladies systématiques</li>
                        <li>-Affections immunologiques et rhumatismales</li>
                        <li>-Troubles de l’équilibre hydrique, électrolytique et acido-basique</li>
                        <li>-Maladies métaboliques</li>
                        <li>-Bilan d’hyperthermie</li>
                        <li>-Bilan inflammatoire</li>
                    </ul>
                </ul>
            </div>
            <div>
                <h3 className='font-bold'>Formation - Stage - recherche :</h3>
                <ul>
                    <li>Le Centre Hospitalier Universitaire d'Anosiala offre des opportunités de formation avancée, de stages pratiques et de recherche clinique, permettant aux étudiants et aux professionnels de développer leurs compétences et de contribuer à l'innovation médicale</li>
                </ul>
            </div>
        </>
    );
};

export default MedecineCard;
