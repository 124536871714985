import { Carousel, Modal, Button } from "flowbite-react";
import { HiArrowSmRight, HiChartPie, HiInbox, HiShoppingBag, HiTable, HiUser, HiViewBoards } from "react-icons/hi";
import { useState } from "react";



import chu_anosiala_img1 from '../assets/caroussel/chu-anosiala_img1.png';
import chu_anosiala_img2 from '../assets/caroussel/chu-anosiala_img2.png';
import chu_anosiala_img3 from '../assets/caroussel/chu-anosiala_img3.png';
import chu_anosiala_img4 from '../assets/caroussel/chu-anosiala_img4.png';
import chu_anosiala_img5 from '../assets/caroussel/chu-anosiala_img5.png';
import chu_anosiala_img6 from '../assets/caroussel/chu-anosiala_img6.png';
import FooterSocialMedia from "./FooterSocialMedia";



import ChirurgieCard from "./ChirurgieCard";
import PediatrieCard from "./PediatrieCard";
import AtuCard from "./AtuCard";
import MedecineCard from "./MedecineCard";
import ReaCard from "./ReaCard";
import LaboratoireCard from "./LaboratoireCard";
import ImagerieCard from "./ImagerieCard";
import AnapathCard from "./AnapathCard";
import MprCard from "./MprCard";
import TeteEtCouCard from "./TeteEtCouCard";
import MtcCard from "./MtcCard";
import PharmacyCard from "./PharmacyCard";


const serviceItems = [
    { id: 1, service: 'ACCUEIL-TRIAGE-URGENCES', contact: '(+261) 38 70 084 62', image: 'menu_atu.jpg', content : AtuCard },
    { id: 2, service: 'MEDECINE POLYVALENTE', contact: '(+261) 38 81 481 15', image: 'menu_medecine.jpg', content : MedecineCard },
    { id: 3, service: 'PÉDIATRIE', contact: '(+261) 38 90 257 71', image: 'menu_pediatrie.jpg', content: PediatrieCard },
    { id: 4, service: 'CHIRURGIE', contact: '(+261) 38 06 936 15', image: 'menu_chirurgie.png', content: ChirurgieCard },
    { id: 5, service: 'REANIMATION', contact: '(+261) 38 80 185 61', image: 'menu_reanimation.jpg', content: ReaCard },
    { id: 6, service: 'LABORATOIRE', contact: '(+261) 38 36 457 18', image: 'menu_laboratoire.jpg', content: LaboratoireCard },
    { id: 4, service: 'IMAGERIE MEDICALE', contact: '(+261) 38 45 005 72', image: 'menu_imagerie.jpg', content: ImagerieCard },
    { id: 4, service: 'LABORATOIRE D’ANATOMIE ET CYTOLOGIE PATHOLOGIQUES', contact: '', image: 'menu_anapath.jpg', content: AnapathCard },
    { id: 4, service: 'MÉDECINE PHYSIQUE ET DE RÉADAPTATION', contact: '(+261) 38 90 530 27', image: 'meu_mpr.jpg', content: MprCard },
    { id: 4, service: 'TETE ET COU', contact: '', image: 'menu_tete_cou.png', content: TeteEtCouCard },
    { id: 4, service: 'MEDECINE TRADITIONNELLE CHINOISE', contact: '(+261) 38 90 257 71', image: 'menu_mtr.jpeg', content: MtcCard },
    { id: 4, service: 'PHARMACIE', contact: '(+261) 38 14 510 28', image: 'menu_pharmacie.jpg', content: PharmacyCard },
];




function Services2() {
    const [openModal, setOpenModal] = useState(false);
    const [service,setService] =useState("");
    const [serviceCard,setServiceCard] = useState(AtuCard);
    const [id , setId] = useState(0);

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 justify-center font-serif">
                <div className=" p-0 bg-red text-white hidden md:flex justify-center ">
                    <div style={{ width: '100%', height: '300px' }}>
                        <Carousel autoPlay infiniteLoop className='justify-center'>
                            <img src={chu_anosiala_img1} alt="Slide 1" />
                            <img src={chu_anosiala_img2} alt="Slide 2" />
                            <img src={chu_anosiala_img3} alt="Slide 3" />
                            <img src={chu_anosiala_img4} alt="Slide 4" />
                            <img src={chu_anosiala_img5} alt="Slide 5" />
                            <img src={chu_anosiala_img6} alt="Slide 6" />
                        </Carousel>
                    </div>

                </div>

                <div className=" p-5  md:p-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center font-serif">
                    {serviceItems.map(item => (
                        <>
                            <div class="relative group rounded-3xl border-red-500">
                                <div class="overflow-hidden rounded-3xl shadow-lg transition transform group-hover:scale-105 cursor-pointer">
                                    <img src={require('../assets/menu/' + item.image)} alt="Service Image" class="w-full h-56 object-cover transition-all duration-500 ease-in-out group-hover:scale-90 opacity-70 rounded-3xl" />
                                </div>

                                <div class="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center rounded-3xl">
                                    <div className="text-center justify-i">
                                        <span className="text-[#39e5f5] text-xl font-bold block" onClick={() => {setOpenModal(true) ; setService(item.service);setServiceCard(item.content)}}>{item.service }</span>
                                        <span className="text-md text-[#c2eaec] md:text-xl font-bold ">{item.contact}</span>
                                    </div>

                                </div>
                            </div>

                            <Modal className="md:w-full" show={openModal} onClose={() => setOpenModal(false)}>
                                <Modal.Header>{service}</Modal.Header>
                                <Modal.Body>
                                    <div>
                                    {serviceCard}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>

                                    <Button color="gray" onClick={() => setOpenModal(false)}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </>
                    ))}
                </div>
            </div>
            <FooterSocialMedia />

        </>
    );
}

export default Services2;

